import React, { Component } from "react";
import { Link } from "react-router-dom";
import SearchForm from "./SearchForm";
import SearchForm2 from "./SearchForm2";
import AgentDetailsModal from "./AgentDetailsModal";

export default class Modal extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      agent: {
        name: "John Doe",
        type: "Agent",
        phone: "1234567890",
        id: "A123",
        facebook: "https://facebook.com/johndoe",
        admin: "Admin Name",
        subadmin: "Sub Admin Name",
        super: "Super Admin Name",
      },
    };
  }



  render(){
  return (
    <>
      {/* search agent Modal */}
      <div
        className="modal fade"
        id="searchAgentModal"
        tabIndex={-1}
        aria-labelledby="searchAgentModalLabel"
        aria-hidden="true"
        data-bs-theme="dark"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content bg-prime">
            <div className="modal-header bg-sec m-3 p-2 border-0 d-flex justify-content-center align-items-center">
              <div
                className="text-center d-flex justify-content-center align-items-center p-0 gap-2"
                id="searchAgentModalLabel"
              >
                <span className="agent-icon bg-white d-flex justify-content-center align-items-center rounded-circle">
                  <i className="fa-solid fa-user text-black" />
                </span>
                <span>Search Agent</span>
              </div>
              <span
                className="close-modal bg-danger rounded-circle d-flex justify-content-center align-items-center"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="fa-solid fa-xmark" />
              </span>
            </div>
            <div className="modal-body">
              <SearchForm2 />
            </div>
          </div>
        </div>
      </div>

      {/* view agent Modal */}
      <div
        className="modal fade"
        id="viewAgentModal"
        tabIndex={-1}
        aria-labelledby="viewAgentModalLabel"
        aria-hidden="true"
        data-bs-theme="dark"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content bg-prime">
            <div className="modal-header bg-sec m-3 p-2 border-0 d-flex justify-content-center align-items-center">
              <div
                className="text-center d-flex justify-content-center align-items-center p-0 gap-2"
                id="viewhAgentModalLabel"
              >
                <span className="agent-icon bg-white d-flex justify-content-center align-items-center rounded-circle">
                  <i className="fa-solid fa-user text-black" />
                </span>
                <span>View Agent</span>
              </div>
              <span
                className="close-modal bg-danger rounded-circle d-flex justify-content-center align-items-center"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="fa-solid fa-xmark" />
              </span>
            </div>
            {/* add the model body here */}
            {/* <AgentDetailsModal/> */}
            <AgentDetailsModal
                  name={this.state.agent.name}
                  type={this.state.agent.type}
                  phone={this.state.agent.phone}
                  id={this.state.agent.id}
                  facebook={this.state.agent.facebook}
                  admin={this.state.agent.admin}
                  subadmin={this.state.agent.subadmin}
                  super={this.state.agent.super}
                />

            {/* <AgentDetailsModal
              name={this.props.name}
              id={this.props.id}
              type={this.props.type}
              phone={this.props.phone}
              uplink={this.props.uplink}
              app={this.props.app}
              keyid={this.props.keyid}
              master={this.props.master}
              super={this.props.super}
              subadmin={this.props.subadmin}
              admin={this.props.admin}
            /> */}
          </div>
        </div>
      </div>

      {/* mobile menu Modal */}
      <div
        className="modal fade"
        id="mobileMenuModal"
        tabIndex={-1}
        aria-labelledby="mobileMenuModalLabel"
        aria-hidden="true"
        data-bs-theme="dark"
        data-bs-dismiss="modal"
      >
        <div className="modal-dialog modal-fullscreen-sm-down">
          <div className="modal-content" style={{ backgroundColor: "#272A34" }}>
            <div className="modal-header m-3 p-2 border-0 d-flex justify-content-center align-items-center">
              <div
                className="text-center d-flex justify-content-center align-items-center p-0 gap-2"
                id="mobileMenuModalLabel"
              >
                <img
                  src="/assets/images/icon.png"
                  alt=""
                  className="img-fluid"
                  style={{ height: "60px" }}
                />
              </div>
              <span
                className="close-modal bg-danger rounded-circle d-flex justify-content-center align-items-center"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="fa-solid fa-xmark" />
              </span>
            </div>
            <div className="modal-body mt-0">
              <div className="mainmenu mainmenu-modal bg-transparent d-flex justify-content-between flex-column align-items-center">
                <div className="d-flex justify-content-center align-items-center w-100 mt-2">
                  <nav>
                    <ul className="d-flex justify-content-center flex-column align-items-center text-white gap-2 gap-md-4 flex-wrap">
                      <button
                        className="d-flex w-100 justify-content-center align-items-center btn bg-white text-dark px-5"
                        data-bs-toggle="modal"
                        data-bs-target="#searchAgentModal"
                      >
                        <i className="fa-solid fa-magnifying-glass text-dark" />
                        Search Agent
                      </button>
                      <li>
                        <Link to="/" className="text-warning">
                          <i className="fa-solid fa-house" />
                          Home
                        </Link>
                      </li>
                      <li>
                        <Link to="/admin">
                          <i className="fa-solid fa-user" />
                          Admin
                        </Link>
                      </li>
                      <li>
                        <Link to="/subadmin">
                          <i className="fa-solid fa-user" />
                          Sub Admin
                        </Link>
                      </li>
                      <li>
                        <Link to="/superadmin">
                          <i className="fa-solid fa-user" />
                          Super
                        </Link>
                      </li>
                      <li>
                        <Link to="/master">
                          <i className="fa-solid fa-user" />
                          Master
                        </Link>
                      </li>
                      <li>
                        <Link to="/affiliate">
                          <i className="fa-solid fa-user" />
                          Join Affiliate
                        </Link>
                      </li>
                      <Link
                        to="/services"
                        className="d-flex w-100 justify-content-center align-items-center btn bg-white text-dark px-5 mt-3"
                      >
                        <i className="fa-solid fa-headset text-dark pe-2" />
                        Support
                      </Link>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );}
}
